import classNames from 'classnames';
import React from 'react';
import { useKeyPressEvent } from 'react-use';

import { t } from '@/utility/localization';

import AnimatedShowHideDiv from '@/components/common/AnimatedShowHideDiv/AnimatedShowHideDiv';
import NPLIconButton from '@/components/npl/NPLIconButton';
import NPLTextArea from '@/components/npl/NPLTextArea/NPLTextArea';

export const MAX_COMMENT_CHAR_LIMIT = 1000;

export type ReplyingToCommentData = {
  [key: string]: unknown;
  _id?: string;
  firstName?: string;
  lastName?: string;
};

type AvatarCommentBoxProps = {
  replyingToCommentData: ReplyingToCommentData;
  setReplyingToCommentData: (data: object) => void;
  avatar?: string;
  commentInputRef?: React.RefObject<HTMLTextAreaElement>;
  hasStickyCommentInput?: boolean;
  isLoading?: boolean;
  isMemberFeed?: boolean;
  isReplying?: boolean;
  isSubmitting?: boolean;
  name?: string;
  noComments?: boolean;
  onChange?: (value: string) => void;
  onSubmit?: () => void;
  value?: string;
};

const AvatarCommentBox: React.FC<AvatarCommentBoxProps> = ({
  onSubmit = () => {},
  avatar = 'https://d2yjtdaqamc55g.cloudfront.net/randomProfileImage16.jpg',
  isLoading = false,
  name,
  onChange = (value: string) => {
    value;
  },
  value = null,
  replyingToCommentData,
  setReplyingToCommentData,
  hasStickyCommentInput,
  isReplying = false,
  isMemberFeed = false,
  noComments = false,
  commentInputRef,
  isSubmitting
}) => {
  const submitComment = (): void => {
    if (value && value.trim().length > 0 && !isSubmitting) {
      onSubmit();
      setReplyingToCommentData({});
    }
  };

  const cancelReply = (): void => {
    setReplyingToCommentData({});
    onChange('');
  };

  useKeyPressEvent('Enter', (event) => {
    if (!event.shiftKey) {
      submitComment();
    }
  });

  return (
    <div
      className={classNames(
        'px-16 pb-16 pt-8 w-full bg-white-default border-t-npl-separator-alpha',
        {
          'sticky bottom-0 left-0 right-0':
            hasStickyCommentInput && !isMemberFeed, // @Trent need to align both feed implementation
          'border-t-1': !noComments
        }
      )}>
      <AnimatedShowHideDiv show={isReplying}>
        <div className="pb-8 flex justify-between items-center">
          <div className="text-label-md text-npl-text-secondary-on-light">
            {t('replying-to')}
            {`${replyingToCommentData?.firstName} ${replyingToCommentData?.lastName}`}
          </div>
          <NPLIconButton
            hierarchy="plain"
            icon="x-close"
            size="sm"
            onClick={cancelReply}
            className="fill-npl-text-secondary-on-light"
          />
        </div>
      </AnimatedShowHideDiv>
      <div className="flex gap-8">
        <div className="flex-shrink-0 mt-16">
          <img
            alt="user-avatar"
            src={avatar}
            className="h-32 w-32 rounded-full"
          />
        </div>
        <div className="flex-grow">
          <NPLTextArea
            nativeTextAreaProps={{
              placeholder: t('add-a-comment'),
              name,
              maxLength: MAX_COMMENT_CHAR_LIMIT
            }}
            minHeight={50}
            value={value}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              onChange(e.target.value)
            }
            error={
              value?.trim()?.length > MAX_COMMENT_CHAR_LIMIT
                ? t('characters-limit-reached', {
                    maxCharacterCount: MAX_COMMENT_CHAR_LIMIT
                  })
                : ''
            }
            ref={commentInputRef}
          />
        </div>
        <AnimatedShowHideDiv
          direction="horizontal"
          show={value}
          className="mt-16">
          <NPLIconButton
            hierarchy="neutral_primary"
            size="md"
            icon="arrow-up"
            loading={isSubmitting}
            onClick={submitComment}
          />
        </AnimatedShowHideDiv>
      </div>
      {isLoading && (
        <div className="absolute left-0 top-0 z-1 grid h-full w-full place-items-center bg-white-default">
          <div className="h-32 w-32 animate-spin rounded-full border-4 border-npl-neutral-light-solid-8 border-t-white-default" />
        </div>
      )}
    </div>
  );
};

export default AvatarCommentBox;
