import classNames from 'classnames';
import React, {
  ForwardRefRenderFunction,
  forwardRef,
  useCallback,
  useEffect,
  useRef
} from 'react';

import NPLInputBottomSection, {
  NPLInputBottomSectionProps
} from '../components/input/NPLInputBottomSection';
import NPLInputTopSection, {
  NPLInputTopSectionProps
} from '../components/input/NPLInputTopSection';

type NPLTextAreaProps = {
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  value: string;
  disabled?: boolean;
  error?: string;
  height?: number;
  inputBottomSectionProps?: Omit<
    NPLInputBottomSectionProps,
    'valueLength' | 'maxLength' | 'error'
  >;
  inputTopSectionProps?: NPLInputTopSectionProps;
  isResizable?: boolean;
  maxHeight?: number;
  maxLength?: number;
  minHeight?: number;
  nativeTextAreaProps?: React.TextareaHTMLAttributes<HTMLTextAreaElement>;
  supportingText?: string; // default 96
};

type NPLTextAreaPropsWithoutRef = Omit<NPLTextAreaProps, 'forwardRef'>;

const NPLTextAreaBase: ForwardRefRenderFunction<
  HTMLTextAreaElement,
  NPLTextAreaProps
> = (
  {
    onChange,
    value,
    disabled,
    error,
    height,
    isResizable,
    maxLength,
    inputTopSectionProps,
    inputBottomSectionProps,
    minHeight = 96,
    maxHeight = 560,
    ...nativeTextAreaProps
  },
  ref
) => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const handleResize = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const textarea = e.target;
      textarea.style.height = 'auto'; // Reset height to auto to calculate the new height
      const newHeight = Math.min(textarea.scrollHeight, maxHeight);
      textarea.style.height = `${newHeight}px`;
    },
    [maxHeight]
  );

  useEffect(() => {
    if (textareaRef.current) {
      const textarea = textareaRef.current;
      textarea.style.height = `${minHeight}px`;
      handleResize({
        target: textarea
      } as React.ChangeEvent<HTMLTextAreaElement>);
    }
  }, [minHeight, handleResize]);

  return (
    <div className="flex flex-col gap-6 max-w-[560px]">
      <NPLInputTopSection {...inputTopSectionProps} />
      {disabled ? (
        <div
          className={`border-1 border-npl-neutral-light-solid-7 text-npl-text-icon-on-light-surface-tertiary px-8 py-12 bg-npl-neutral-light-solid-3 rounded-8 
          shadow-npl-styles-button-shadow whitespace-pre-wrap min-h-[${minHeight}px] h-[${minHeight}px]`}>
          {value}
        </div>
      ) : (
        <textarea
          ref={(node) => {
            textareaRef.current = node;
            if (typeof ref === 'function') {
              ref(node);
            } else if (ref) {
              (
                ref as React.MutableRefObject<HTMLTextAreaElement | null>
              ).current = node;
            }
          }}
          style={{
            height: height ? `${height}px` : `${minHeight}px`
          }}
          rows={1}
          className={classNames(
            `transition-all max-h-[${maxHeight}px] shadow-npl-styles-button-shadow h-full py-12 px-8 outline outline-1 rounded-8 text-body-md text-npl-text-icon-on-light-surface-primary min-h-[${minHeight}px] w-full`,
            {
              'outline-npl-error-light-9 focus:ring-2 focus:ring-npl-error-light-9 focus:outline-none':
                error,
              'outline-npl-neutral-light-alpha-7 bg-white-default hover:outline-npl-neutral-light-alpha-8 focus:ring-2 focus:ring-npl-neutral-light-alpha-8 focus:outline-none':
                !error,
              'resize-none': !isResizable
            }
          )}
          onChange={(e) => {
            onChange(e);
            if (!height) {
              handleResize(e); // Only auto-resize if no height prop
            }
          }}
          value={value}
          disabled={disabled}
          {...nativeTextAreaProps}
        />
      )}
      <NPLInputBottomSection
        {...inputBottomSectionProps}
        error={error}
        valueLength={value?.length ?? 0}
        maxLength={maxLength}
      />
    </div>
  );
};

const NPLTextArea = forwardRef<
  HTMLTextAreaElement,
  NPLTextAreaPropsWithoutRef
>(NPLTextAreaBase);

export default NPLTextArea;
